module.exports = {
  API_BFF_URL: 'https://api-uat.mcdchina.net',
  CDN_PROJ_BASE_URL: "https://cdn-uat.mcdchina.net/m/",
  PARTNER: {
    CCB_OAUTH_URL: 'https://paymenttest.bankcomm.com/oautht/authorize',
    CCB_CLIENT_ID: 'mdb_to_mcd',
    CCB_RESPONSE_TYPE: 'code',
    CCB_REDIRECT_URI: 'https://m-uat.mcdchina.net/partner/login',
    CCB_MERCHANT_ID: 'M98168831',
  },
  AGENT_ID: '1000087'
}